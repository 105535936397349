:root {
  // ALL COLORS OF ISPUI-LINK v5.0.4
  --ispui-link__color-additional: var(--isp-c-grey-weekdays);
  --ispui-color__additional-text: var(--isp-c-grey-weekdays);
  --ispui-link__color_fail: var(--isp-dc-bright-emotions);
  --ispui-link__color_fail_hover: var(---isp-dc-bright-emotions);
  --ispui-link__color_fail_active: var(--isp-c-mabel-pines);
  --ispui-link__color_inverse: var(--isp-c-happy-bob);
  --ispui-link__color_inverse_hover: var(--isp-c-happy-bob);
  --ispui-link__color_inverse_active: var(--isp-c-c-3po);
  --ispui-link__color_regular: var(--isp-dc-fremen);
  --ispui-link__color_hover: var(--isp-dc-lapis-lazuli);
  --ispui-link__color_active: var(--isp-dc-fremen);
  --ispui-link__color_inactive: var(--isp-c-grey-weekdays);
  --ispui-link__color_success: var(--isp-c-grasshopper);
  --ispui-link__color_success_hover: var(--isp-c-shire);
  --ispui-link__color_success_active: var(--isp-c-grasshopper);
}
