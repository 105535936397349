body {
  display: flex;

  min-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--isp-dc-highway-star);
  background-color: var(--isp-dc-monochrome);

  font-family: "Ubuntu", sans-serif;

  overflow: overlay;
}
