:root {
  /*
   * New color
   */
  --isp-c-interstellar: #1a1a1a;

  /*
   * New color
   */
  --isp-c-black-cat: #262626;

  /*
   * New color
   */
  --isp-c-darth-vader: #333333;

  /*
   * New color
   * Replaced: #383838 #2d4053
   * Renamed: --isp-dark-gray-2
   */
  --isp-c-stranger-things: #404040;

  /*
   * Renamed: --isp-dark-gray-3
   * Replaced: #585858 #47586a
   */
  --isp-c-creepy-cloud: #595959;

  /*
   * Renamed: --isp-gray-4
   * Replaced: #617283
   * Merged with:
   * --isp-light-gray-15: #707070
   * --isp-gray-3: #5d6f7f
   */
  --isp-c-lovely-puddle: #727272;

  /*
   * Renamed: --isp-light-gray-3
   * Replaced: #979797 #8a99a8
   * Merged with:
   * --isp-light-gray-14: #878787
   */
  --isp-c-grey-weekdays: #999999;

  /*
   * Renamed: --isp-light-gray-1
   * Replaced: #d3d9df
   * Merged with:
   * --isp-light-gray-20: #c2c2c2
   */
  --isp-c-inconspicuous: #d9d9d9;

  /*
   * Renamed: --isp-light-gray-9
   * Replaced: #ebebeb #eeeeee #e2e6ea
   * Replaced:
   * Merged with:
   * --isp-light-gray-10: #e6e6e6
   * --isp-grey-opacity-2: #d2d2d280
   */
  --isp-c-gandalf-the-grey: #e6e6e6;

  /*
   * Renamed: --isp-light-gray-6
   * Replaced: #f4f4f4 #f0f2f4
   */
  --isp-c-russian-north: #f2f2f2;

  /*
   * Renamed: --isp-white
   */
  --isp-c-jon-snow: #ffffff;

  /*
   * New color
   */
  --isp-c-devilman: #2e1f24;

  /*
   * New color
   */
  --isp-c-donor: #7d3641;

  /*
   * Renamed: --isp-pink-8
   * Replaced: #db0444
   * Merged with:
   * --isp-pink-11: #ba0d41
   * !REPLACE ME! Banner danger border: #e96488
   */
  --isp-c-nosferatu: #e50026;

  /*
   * Renamed: --isp-pink-7
   * Replaced: #ff487f
   */
  --isp-c-mabel-pines: #ff4c6a;

  /*
   * New color
   */
  --isp-c-mister-pink: #ff99aa;

  /*
   * Renamed: --isp-light-pink-1
   * Replaced: #fdf3f1
   * Merged with:
   * --isp-red-opacity: #e5499326
   * !REPLACE ME! Banner danger background: #e96488
   */
  --isp-c-natasha-rostova-dress: #fff0f2;

  /*
   * New color
   */
  --isp-c-dead-sun: #2e2c1f;

  /*
   * New color
   */
  --isp-c-hangover: #7d7136;

  /*
   * Renamed: --isp-yellow-3
   * Replaced: #f2a504
   * Merged with:
   * --isp-orange-3: #ff7a1a
   * !REPLACE ME! Banner warning border: #f3845e
   * Link inverse: #ffd100
   */
  --isp-c-c-3po: #ffaa00;

  /*
   * Renamed: --isp-yellow-6
   * Replaced: #ffe03a
   * Merged with:
   * --isp-yellow-7: #f8e71b
   * --isp-yellow-9: #faef66
   * #f8e71c
   * #fff680
   */
  --isp-c-happy-bob: #ffdd33;

  /*
   * New color
   */
  --isp-c-honey-block: #ffee99;

  /*
   * Renamed: --isp-yellow-8
   * Replaced: #fffabb
   * Merged with:
   * --isp-yellow-11: #fef0d8
   * --isp-orange-1: #ffe9cf
   * --isp-orange-2: #fff5ec
   */
  --isp-c-moon-prism-power: #fffbe5;

  /*
   * New color
   */
  --isp-c-gyokuro: #1f2e26;

  /**
   * New color
   */
  --isp-c-green-mile: #367d59;

  /*
   * Renamed: --isp-green-3
   * Replaced: #1d9052
   * Merged with:
   * --isp-green-5: #1c9b7d
   * --isp-cyan-1: #0b9898
   */
  --isp-c-grasshopper: #1fad66;

  /*
   * Renamed: --isp-green-2
   * Replaced: #65c84d
   */
  --isp-c-shire: #26d97f;

  /*
   * New color
   */
  --isp-c-emerald-splash: #a8f0cc;

  /*
   * Renamed: --isp-light-green-1
   * Replaced: #e7fbe8
   * Merged with:
   * --isp-green-opacity: #30ba9a33
   */
  --isp-c-celery-juice: #e9fbf2;

  /*
   * New color
   */
  --isp-c-echo: #172736;

  /*
   * Renamed: --isp-dark-gray-1
   * Replaced: #24262a
   * Merged with:
   * --isp-dark-blue-2: #1f364a
   * rgba(255, 255, 255, 0.2)
   */
  --isp-c-malewicz: #1f3347;

  /*
   * New color
   */
  --isp-c-silent-hill: #36597d;

  /*
   * Renamed: --isp-blue-8
   * Replaced: #075bbe
   */
  --isp-c-deep: #1467b8;

  /*
   * Renamed: --isp-blue-6
   * Replaced: #1a76e2
   * Merged with:
   * --isp-blue-9: #1b88fe
   * --isp-blue-10: #abbafc
   */
  --isp-c-blue-velvet: #1a81e5;

  /*
   * New color
   */
  --isp-c-mood: #4ca7ff;

  /*
   * Renamed: --isp-light-blue-11
   * Replaced: #afcfff
   * Merged with:
   * --isp-blue-12: #58bdf9
   */
  --isp-c-fantomas: #a3ccf5;

  /*
   * Renamed: --isp-light-blue-13 and --isp-c-dream
   * Replaced: #c2daff
   */
  --isp-c-sweet-dreams: #bad9f7;

  /*
   * Renamed: --isp-light-blue-12
   * Replaced: #dbe8ff
   * Merged with:
   * --isp-light-blue-7: #daebff
   * --isp-light-blue-10: #c4e9ff
   */
  --isp-c-blue-frost: #d1e6fa;

  /*
   * Renamed: --isp-light-blue-1, --isp-light-blue-9
   * Replaced: #e9f2ff, #f3f9ff
   * Merged with:
   * --isp--c-baby-tear: #f6fafe
   * --isp-blue-opacity: #0279c026
   * --isp-light-blue-8: #f4f8fe
   */
  --isp-c-crystal-castles: #e8f2fc;

  /*
   * New color
   */
  --isp-c-odysseus: #2eb8b8;

  /*
   * Renamed: --isp-text-color-main-opacity
   * Replaced: #1f364a80
   * Merged with:
   * rgba(52, 74, 94, 0.56)
   * rgba(52, 74, 94, 0.8)
   * !REPLACE ME! Drawers overlay background
   */
  --isp-c-desolation-of-smaug: #0000004d;

  /*
   * New color
   */
  --isp-c-wedding-dress: #ffffffb2;

  // DYNAMIC COLORS

  // Replaces to '--isp-c-stranger-things' in dark theme
  --isp-dc-brotherhood-of-steel: var(--isp-c-lovely-puddle);

  // Replaces to '--isp-c-stranger-things' in dark theme
  --isp-dc-charlie-chaplin: var(--isp-c-inconspicuous);

  // Replaces to '--isp-c-stranger-things' in dark theme
  --isp-dc-reincarnation: var(--isp-c-gandalf-the-grey);

  // Replaces to '--isp-c-darth-vader' in dark theme
  --isp-dc-norilsk: var(--isp-c-russian-north);

  // Replaces to '--isp-c-interstellar' in dark theme
  --isp-dc-monochrome: var(--isp-c-jon-snow);

  // Replaces to '--isp-c-gandalf-the-grey' in dark theme
  --isp-dc-highway-star: var(--isp-c-malewicz);

  // Replaces to '--isp-c-blue-velvet' in dark theme
  --isp-dc-lapis-lazuli: var(--isp-c-deep);

  // Replaces to '--isp-c-mood' in dark theme
  --isp-dc-fremen: var(--isp-c-blue-velvet);

  // Replaces to '--isp-c-silent-hill' in dark theme
  --isp-dc-the-institute: var(--isp-c-fantomas);

  // Replaces to '--isp-c-malewicz' in dark theme
  --isp-dc-mass-relay: var(--isp-c-blue-frost);

  // Replaces to '--isp-c-echo' in dark theme
  --isp-dc-made-in-heaven: var(--isp-c-crystal-castles);

  // Replaces to '--isp-c-malewicz' in dark theme
  --isp-dc-cult: var(--isp-c-crystal-castles);

  // Replaces to '--isp-c-silent-hill' in dark theme
  --isp-dc-sodalite: var(--isp-c-fantomas);

  // Replaces to '--isp-c-gyokuro' in dark theme
  --isp-dc-aurora-borealis: var(--isp-c-celery-juice);

  // Replaces to '--isp-c-green-mile' in dark theme
  --isp-dc-alexandrite: var(--isp-c-emerald-splash);

  // Replaces to '--isp-c-devilman' in dark theme
  --isp-dc-dirty-deeds-done-dirt-cheap: var(--isp-c-natasha-rostova-dress);

  // Replaces to '--isp-c-donor' in dark theme
  --isp-dc-rhodochrosite: var(--isp-c-mister-pink);

  // Replaces to '--isp-c-mabel-pines' in dark theme
  --isp-dc-bright-emotions: var(--isp-c-nosferatu);

  // Replaces to '--isp-c-dead-sun' in dark theme
  --isp-dc-new-vegas: var(--isp-c-moon-prism-power);

  // Replaces to '--isp-c-hangover' in dark theme
  --isp-dc-heliodor: var(--isp-c-honey-block);

  // Replaces to '--isp-c-black-cat' in dark theme
  --isp-dc-mimic: var(--isp-c-russian-north);

  // Replaces to '--isp-c-black-cat' in dark theme
  --isp-dc-cow: var(--isp-c-jon-snow);

  // Replaces to '--isp-c-desolation-of-smaug' in dark theme
  --isp-dc-glass: var(--isp-c-wedding-dress);

  // Replaces to '--isp-c-interstellar' in dark theme
  --isp-dc-zebra: var(--isp-c-russian-north);

  color-scheme: light;
}

[data-color-scheme="dark"] {
  // Replaces to '--isp-c-lovely-puddle' in light theme
  --isp-dc-brotherhood-of-steel: var(--isp-c-stranger-things);

  // Replaces to '--isp-c-inconspicuous' in light theme
  --isp-dc-charlie-chaplin: var(--isp-c-stranger-things);

  // Replaces to '--isp-c-gandalf-the-grey' in light theme
  --isp-dc-reincarnation: var(--isp-c-stranger-things);

  // Replaces to '--isp-c-russian-north' in light theme
  --isp-dc-norilsk: var(--isp-c-darth-vader);

  // Replaces to '--isp-c-jon-snow' in light theme
  --isp-dc-monochrome: var(--isp-c-interstellar);

  // Replaces to '--isp-c-malewicz' in light theme
  --isp-dc-highway-star: var(--isp-c-gandalf-the-grey);

  // Replaces to '--isp-c-deep' in dark theme
  --isp-dc-lapis-lazuli: var(--isp-c-blue-velvet);

  // Replaces to '--isp-c-blue-velvet' in light theme
  --isp-dc-fremen: var(--isp-c-mood);

  // Replaces to '--isp-c-fantomas' in light theme
  --isp-dc-the-institute: var(--isp-c-silent-hill);

  // Replaces to '--isp-c-blue-frost' in light theme
  --isp-dc-mass-relay: var(--isp-c-malewicz);

  // Replaces to '--isp-c-crystal-castles' in light theme
  --isp-dc-made-in-heaven: var(--isp-c-echo);

  // Replaces to '--isp-c-crystal-castles' in light theme
  --isp-dc-cult: var(--isp-c-malewicz);

  // Replaces to '--isp-c-fantomas' in light theme
  --isp-dc-sodalite: var(--isp-c-silent-hill);

  // Replaces to '--isp-c-celery-juice' in light theme
  --isp-dc-aurora-borealis: var(--isp-c-gyokuro);

  // Replaces to '--isp-c-emerald-splash' in light theme
  --isp-dc-alexandrite: var(--isp-c-green-mile);

  // Replaces to '--isp-c-natasha-rostova-dress' in light theme
  --isp-dc-dirty-deeds-done-dirt-cheap: var(--isp-c-devilman);

  // Replaces to '--isp-c-mister-pink' in light theme
  --isp-dc-rhodochrosite: var(--isp-c-donor);

  // Replaces to '--isp-c-nosferatu' in light theme
  --isp-dc-bright-emotions: var(--isp-c-mabel-pines);

  // Replaces to '--isp-c-moon-prism-power' in light theme
  --isp-dc-new-vegas: var(--isp-c-dead-sun);

  // Replaces to '--isp-c-honey-block' in light theme
  --isp-dc-heliodor: var(--isp-c-hangover);

  // Replaces to '--isp-c-russian-north' in light theme
  --isp-dc-mimic: var(--isp-c-black-cat);

  // Replaces to '--isp-c-jon-snow' in light theme
  --isp-dc-cow: var(--isp-c-black-cat);

  // Replaces to '--isp-c-wedding-dress' in light theme
  --isp-dc-glass: var(--isp-c-desolation-of-smaug);

  // Replaces to '--isp-c-russian-north' in light theme
  --isp-dc-zebra: var(--isp-c-interstellar);

  color-scheme: dark;
}
