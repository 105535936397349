:root {
  // ALL COLORS OF ISPUI-CHECKBOX v1.3.6-feature-inputv6-component.0
  --ispui-checkbox__font-color: var(--isp-dc-highway-star);
  --ispui-checkbox__border-color: var(--isp-c-grey-weekdays);
  --ispui-checkbox__background-color: var(--isp-dc-monochrome);
  --ispui-checkbox__background-image-icon-empty: "";
  // checked
  --ispui-checkbox__border-color-checked: var(--isp-c-blue-velvet);
  --ispui-checkbox__background-color-checked: var(--isp-dc-monochrome);
  --ispui-checkbox__background-image-icon-checked: var(
    --isp-checkbox-checked-image
  );
  // disabled
  --ispui-checkbox__font-color-disabled: var(--isp-c-grey-weekdays);
  --ispui-checkbox__border-color-disabled: var(--isp-dc-charlie-chaplin);
  --ispui-checkbox__background-color-disabled: var(--isp-dc-norilsk);
  --ispui-checkbox__background-image-icon-disabled: "";
  --ispui-checkbox__background-image-icon-checked-disabled: var(
    --isp-checkbox-checked-image-disabled
  );
  // focused
  --ispui-checkbox__focus-border-color: #187abd4d;
}
