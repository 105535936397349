:root {
  // ALL COLORS OF ISPUI-SELECT v0.2.6-feature-inputv6-component.0

  --ispui-select-button__color: var(--isp-dc-highway-star);
  --ispui-select-button__arrow-border: 1px solid var(--isp-dc-highway-star);
  --ispui-select-button__border-focus: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-search__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-search__arrow-border: 1px solid
    var(--isp-dc-highway-star);
  --ispui-select-button-search__border-focus: 1px solid var(--isp-dc-fremen);
  --ispui-select-search__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-legend__border: 1px solid var(--isp-dc-charlie-chaplin);
  --ispui-select-button-legend__color: var(--isp-dc-highway-star);
  --ispui-select-button-legend__legend-color: var(--isp-c-lovely-puddle);
  --ispui-select-button-legend__border-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__legend-color-focus: var(--isp-text-color-link);
  --ispui-select-button-legend__arrow-border: 1px solid
    var(--isp-dc-highway-star);
  --ispui-select-button-legend__br: var(--isp-border-radius-main);
  --ispui-select-button-legend__asterisk-color: var(--isp-dc-bright-emotions);
  --ispui-select-button-line__color: var(--isp-dc-highway-star);
  --ispui-select-button-line__arrow-border: 1px solid var(--isp-dc-highway-star);
  --ispui-select-button-line__focus-color: var(--isp-dc-fremen);
  --ispui-select-button-chip__color: var(--isp-dc-fremen);
  --ispui-select-button-chipt__border: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-chip__arrow-border: 1px solid var(--isp-dc-fremen);
  --ispui-select-button-chipt__border-focus: 2px solid var(--isp-dc-fremen);
  --ispui-select-list__item-color: var(--isp-dc-highway-star);
  --ispui-select-list__item-highlight-color: var(--isp-dc-cult);
  --ispui-select-list__disabled-item-color: var(--isp-dc-charlie-chaplin);
  --ispui-select-list__max-height: 350px;
  --ispui-select-list__item-br: var(--isp-border-radius-main);
  --ispui-select-list__item-checkbox-pl: 15px;
  --ispui-select-list__item-search-match-bg: var(--isp-c-happy-bob);
  --ispui-select-button-search__color: var(--isp-dc-highway-star);
  --ispui-button__fourth_text_regular: var(--isp-dc-fremen);
  --ispui-button__fourth_text_hover: var(--isp-dc-lapis-lazuli);
  --ispui-button__fourth_text_active: var(--isp-dc-fremen);

  --ispui-select-list__scroll-width: 5px;
  --ispui-select-list__scroll-border-radius: 10px;
  --ispui-select-list__scroll-track-color: var(--isp-dc-charlie-chaplin);
  --ispui-select-list__scroll-bar-color: var(--isp-c-grey-weekdays);
  --ispui-select-list__scroll-bar-color-hover: var(--isp-c-blue-velvet);

  // this variables are not exist in ispui-select! Added manualy through builderParams! add them in library!
  --ispui-select-search__padding: 10px;
  --ispui-select-search__margin: 0;
  --ispui-select-list__margin: 0 0 0 10px;
  --ispui-select-list__padding: 0 10px 0 0;
  --ispui-select-list__min-width: calc(400px - 20px);
  --ispui-select-list__max-width: calc(400px - 20px);

  --ispui-select-search__br: var(--isp-border-radius-main);

  .ispui-select-search_input {
    background: none;
  }

  .ispui-select-list__item-search-match {
    color: var(--isp-c-malewicz);
  }
}
