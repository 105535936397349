body {
  > .isp-layout {
    display: flex;
    width: 100%;

    > .isp-background {
      flex-grow: 1;
      flex-shrink: 1;

      .isp-background-image {
        position: fixed;

        width: 100%;
        height: 100%;

        right: 700px;

        background-repeat: repeat;
        background-size: auto 100%;
        background-position-x: right;
        background-position-y: top;
      }
    }

    > .isp-page {
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      max-width: 700px;
      min-width: 700px;

      background-color: var(--isp-dc-monochrome);
      padding: 0 0 30px 0;

      > .isp-page__top-padding {
        height: 100%;
        min-height: 30px;
        max-height: 200px;

        flex-shrink: 0.95;
      }

      > .isp-page__logo-indent {
        height: 100%;
        min-height: 30px;
        max-height: 130px;

        flex-shrink: 1;
      }
    }
  }
}
